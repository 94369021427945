.body {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(12, 12, 35);
  color: white;
}
.body > div {
  width: 50%;
  display: flex;
  align-items: center;
}
.body > div:first-of-type {
  padding-left: 100px;
  justify-content: space-around;
  gap: 50px;
}
.body > div:first-of-type > div > ul {
  list-style: none;
  padding: 0;
}
.body > div:first-of-type > div > ul > li {
  margin-bottom: 10px;
}
.body > div:last-of-type {
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .body {
    height: auto;
    display: block;
    text-align: center;
  }
  .body > div {
    width: 100%;
    flex-wrap: wrap;
  }
  .body > div:first-of-type {
    padding-left: 0;
    justify-content: space-around;
    gap: 0;
  }
  .body > div:last-of-type {
    /* justify-content: center; */
    text-align: center;
  }
}
