.body {
  width: 80%;
  margin: 20px auto;
}
.cardContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.preFooterTitle {
  font-size: 40px;
}
.cardClass {
  width: 40%;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}
.imageContainerClass {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageContainerClass > img {
  width: 60%;
  /* aspect-ratio: 1/1; */
}
.discountContainerClass {
  display: flex;
  margin: 10px 0;
  gap: 10px;
  align-items: center;
}
.discountContainerClass > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 4px;
  background-color: rgba(135, 207, 235, 0.277);
}
.cardTitleClass {
  text-align: center;
  margin-bottom: 10px;
}
.cardDescClass {
  word-spacing: 4px;
  color: gray;
  text-align: center;
}
.pricingContainerClass {
  margin: 8px 0;
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.spClass {
  color: gray;
}
.mrpClass {
  font-size: small;
  color: lightgray;
}
.discountRateClass {
  color: red;
}
.viewContainerClass {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: royalblue;
  border-radius: 8px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .cardContainer {
    flex-wrap: wrap;
  }
  .cardClass {
    width: 100%;
  }
}
