.body {
  width: 80%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body > h1 {
  width: 40%;
  font-size: 40px;
}
.signupInput {
  display: flex;
}
.signupInput > input,
.signupInput > button {
  border: none;
}
.signupInput > input {
  width: 80%;
  font-size: 40px;
  border-radius: 8px 0 0 8px;
  padding: 10px 20px;
}
.signupInput > input:focus {
  outline: inset royalblue;
  outline-offset: -3px;
}
.signupInput > button {
  width: 20%;
  background-color: royalblue;
  color: white;
  border-radius: 0 8px 8px 0;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .body {
    width: 100%;
    flex-wrap: wrap;
  }
}
